<template>
  <div>
    <el-dialog
      @close="closePanel"
      width="45%"
      :title="this.form.id ? '编辑藏品发行信息' : '新增藏品发行信息'"
      :visible.sync="dialogshow"
      :close-on-click-modal="modal"
    >
      <el-form
        :model="form"
        label-width="120px"
        :rules="rules"
        ref="myForm"
        required
      >
        <el-form-item label="藏品名称：" prop="NFTName">
          <el-input
            placeholder="请输入藏品名称"
            v-model="form.NFTName"
          ></el-input>
        </el-form-item>

        <el-form-item label="所属品牌：" prop="brandID">
          <el-select placeholder="请选择所属品牌" v-model="form.brandID">
            <el-option
              v-for="(item, index) in optionsBrand"
              :key="index"
              :label="item.brandName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="藏品集：" prop="CollectionID">
          <el-select placeholder="请选择藏品集" v-model="form.collectionID">
            <el-option
              v-for="(item, index) in optionsCollection"
              :key="index"
              :label="item.collectionName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发行数量：" prop="totalCount">
          <el-input
            placeholder="请输入发行数量"
            v-model="form.totalCount"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品描述：" prop="describtion">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入商品描述"
            v-model="form.describtion"
          ></el-input>
        </el-form-item>

        <el-form-item label="藏品图片：" prop="license">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
            :show-file-list="false"
            :file-list="licenseList"
            :on-change="setLicenseList"
            :before-upload="beforeLicenseUpload"
            :http-request="doLicenseUpload"
          >
            <el-image
              v-if="form.license"
              style="width: 360px; height: 180px"
              :src="form.license"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="藏品缩略图：" prop="NFTThumbnailUrl">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
            :show-file-list="false"
            :file-list="NFTThumbnailUrlList"
            :on-change="setNFTThumbnailUrlList"
            :before-upload="beforeLicenseUpload"
            :http-request="doNFTThumbnailUrlUpload"
          >
            <el-image
              v-if="form.NFTThumbnailUrl"
              style="width: 360px; height: 180px"
              :src="form.NFTThumbnailUrl"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="藏品原图：" prop="NFTHDUrl">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".jpg, .jpeg, .png,.gif"
            :show-file-list="false"
            :file-list="NFTHDUrlList"
            :on-change="setNFTHDUrlList"
            :before-upload="beforeLicenseUpload"
            :http-request="doNFTHDUrlUpload"
          >
            <el-image
              v-if="form.NFTHDUrl"
              style="width: 360px; height: 180px"
              :src="form.NFTHDUrl"
              fit="contain"
            ></el-image>
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持PNG、JPG或Gif格式图片，图片大小不超过30M）
            </div>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="btn">
        <el-button @click="closePanel" size="small">取 消</el-button>
        <el-button type="primary" @click="doValidate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FileUpload, FilesUpload } from "@/api/common";
import { BrandQuery } from "@/api/module/brand";
import { CollectionQuery } from "@/api/module/collection";
import { NftCreate, NftUpdate, NftDetail } from "@/api/module/nft";

let Brarules = {
  NFTHDUrl: [
    {
      required: true,
      message: "请上传藏品原图",
      trigger: "change",
    },
  ],
  NFTThumbnailUrl: [
    {
      required: true,
      message: "请上传藏品缩略图",
      trigger: "change",
    },
  ],
  NFTName: [
    {
      required: true,
      message: "请输入藏品名称",
      trigger: "blur",
    },
  ],
  describtion: [
    {
      required: true,
      message: "请输入商品描述",
      trigger: "blur",
    },
  ],
  totalCount: [
    {
      required: true,
      message: "请输入发行数量",
      trigger: "blur",
    },
  ],
  license: [
    {
      required: true,
      message: "请上传藏品图",
      trigger: "change",
    },
  ],
  brandID: [
    {
      required: true,
      message: "请选择所属品牌",
      trigger: "change",
    },
  ],
  collectionID: [
    {
      required: true,
      message: "请选择藏品集",
      trigger: "change",
    },
  ],
};

export default {
  name: "NftPanel",
  props: ["dialogshow", "id"],
  data() {
    return {
      modal: false,
      optionsBrand: [],
      optionsCollection: [],
      NFTHDUrlList: [],
      NFTThumbnailUrlList: [],
      licenseList: [],
      form: {
        brandID: "",
        collectionID: "",
        license: "",
        describtion: "",
        NFTName: "",
        totalCount: "",
        NFTThumbnailUrl: "",
        NFTHDUrl: "",
      },
      rules: Brarules,
    };
  },
  created() {
    this.getPackage();
  },
  methods: {
    doNFTHDUrlUpload() {
      let oData = new FormData();
      const oFileList = this.NFTHDUrlList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.NFTHDUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("NFTHDUrl");
      });
      this.NFTHDUrlList = [];
    },
    setNFTHDUrlList(file, fileList) {
      this.NFTHDUrlList = fileList;
    },
    doNFTThumbnailUrlUpload() {
      let oData = new FormData();
      const oFileList = this.NFTThumbnailUrlList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.NFTThumbnailUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("NFTThumbnailUrl");
      });
      this.NFTThumbnailUrlList = [];
    },
    setNFTThumbnailUrlList(file, fileList) {
      this.NFTThumbnailUrlList = fileList;
    },
    // 获取选项
    async getPackage() {
      const rb = await BrandQuery(`?pagination=false&current=1&pageSize=10`);
      const rc = await CollectionQuery(
        `?pagination=false&current=1&pageSize=10&status=3`
      );
      this.optionsCollection = rc.list;
      this.optionsBrand = rb.list;
    },

    doValidate() {
      let f = this.form;
      //   新增
      let oData = {
        brandID: f.brandID,
        NFTUrl: f.license,
        describtion: f.describtion,
        collectionID: f.collectionID,
        totalCount: Number(f.totalCount),
        NFTName: f.NFTName,
        NFTThumbnailUrl: f.NFTThumbnailUrl,
        NFTHDUrl: f.NFTHDUrl,
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          if (this.form.id) {
            const r = await NftUpdate(this.form.id, oData);
          } else {
            const r = await NftCreate(oData);
          }
          //   全局验证通过;
          this.$message({
            type: "success",
            message: this.form.id ? "编辑成功" : "创建成功",
            duration: 1300,
          });
          this.$emit("update:dialogshow", false);
          //调用父组件的方法
          this.$emit("updata");
          this.$refs["myForm"].resetFields(); // 在初始化所有字段
        } else {
          //全局验证不通过
        }
      });
    },
    closePanel() {
      this.form = {
        brandID: "",
        collectionID: "",
        license: "",
        describtion: "",
        NFTName: "",
        totalCount: "",
        NFTHDUrl: "",
      };
      this.$emit("update:dialogshow", false);
      this.$refs.myForm.resetFields();
    },

    // 选择轮播图
    setLicenseList(file, fileList) {
      this.licenseList = fileList;
    },
    // 限制轮播图大小与格式
    beforeLicenseUpload(file) {
      if (["jpeg", "png", "jpg", "gif"].indexOf(file.type.split("/")[1]) < 0) {
        this.$message.error("上传图片只能是JPG、PNG或gif格式!");
        return false;
      }
      const sizeLimit = file.size / 1024 / 1024 < 30;
      if (!sizeLimit) {
        this.$message.error("上传图片大小不能超过 30MB!");
        return false;
      }
      return true;
    },

    // 上传轮播图
    doLicenseUpload() {
      let oData = new FormData();
      const oFileList = this.licenseList;
      console.log("ofle", oFileList);
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("file", oFileList[i].raw);
      }
      FileUpload(oData).then((r) => {
        this.form.license = r.data.file.url;
        this.$refs["myForm"].clearValidate("license");
      });
      this.licenseList = [];
    },
  },
};
</script>

